export const GET_REQUEST = 'GET_REQUEST';
export const GET_PAYLOAD = 'GET_PAYLOAD';
export const GET_ERROR = 'GET_ERROR';

export const GET_MORE_REQUEST = 'GET_MORE_REQUEST';
export const GET_MORE_PAYLOAD = 'GET_MORE_PAYLOAD';

export const RESET = 'RESET';

export const podcasts = 'podcasts';
